const openModal = () => {
  const $newsletterModal = document.querySelector("[data-newsletter-popup]");
  if ($newsletterModal) {
    $newsletterModal.classList.remove("hidden");
  }
};

const closeModal = () => {
  const $newsletterModal = document.querySelector("[data-newsletter-popup]");
  if ($newsletterModal) {
    $newsletterModal.classList.add("hidden");
  }
};

const getNewsletterInput = () => {
  const $newsletterInput = document.querySelector("[data-newsletter-input]");
  if ($newsletterInput) {
    const inputValue = String($newsletterInput.value).trim();
    return inputValue;
  }
  return "";
};

/**
 * Checks if value is valid email. Based on w3.org
 * @param {string} value - value to check.
 * @returns {boolean}
 */
const isEmailValidator = (value) =>
  // https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
  value
    ? !!value.match(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
      )
    : true;
const validateEmail = (emailInput) => isEmailValidator(emailInput);

const sendSubscribe = async (email) => {
  const response = await fetch(`https://elevercosmetics.pl/misc/newsletteradd/email/${email}`, {
    method: "GET",
    mode: "cors",
    cache: "default",
  });

  response.status === 200 ? openModal() : null;
};

const handleNewsletterSub = () => {
  const newsletterInput = getNewsletterInput();
  validateEmail(newsletterInput) ? sendSubscribe(newsletterInput) : alert("Niepoprawny email");
};

const handleNewsletterCloseButtons = () => {
  closeModal();
};

const initNewsletterInputs = () => {
  const $newsletterButtons = document.querySelectorAll("[data-newsletter-button]");
  $newsletterButtons.forEach(($singleNewsletterButton) => {
    $singleNewsletterButton.disabled = false;
    $singleNewsletterButton.addEventListener("click", handleNewsletterSub);
  });
  const $closeNEwsletterButtons = document.querySelectorAll("[data-close-newsletter-popup]");
  $closeNEwsletterButtons.forEach(($closeNEwsletterButton) => {
    $closeNEwsletterButton.disabled = false;
    $closeNEwsletterButton.addEventListener("click", handleNewsletterCloseButtons);
  });
};

export function initNewsletter() {
  initNewsletterInputs();
}

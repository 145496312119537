import Glide, { Controls, Autoplay, Anchors} from "../../vendors/glidejs/glide.3.5.2.esm.js";

const initTopSlider = () => {
  const $sliderElement = document.querySelector("[data-glider='frontpage-top']");
  if ($sliderElement) {
    new Glide($sliderElement, {
      startAt: 0,
      perView: 1,
      gap:0,
      autoplay: 5000,
    }).mount({ Controls, Autoplay, Anchors });
  }
};

export function initFrontpageTopSlider() {
  initTopSlider();
}

import Glide, { Controls, Autoplay, Swipe, Breakpoints} from "../../vendors/glidejs/glide.3.5.2.esm.js";

const initMediaSlider = () => {
  const $sliderElement = document.querySelector("[data-glider='frontpage-media']");
  if ($sliderElement) {
    new Glide($sliderElement, {
      type: "carousel",
      startAt: 0,
      perView: 5,
      autoplay: 5000,
      breakpoints: {
        1250: {
          perView: 4
        },
        990: {
          perView: 3
        },
        660: {
          perView: 2
        },
        480: {
          perView: 1
        }
      }
    }).mount({ Controls, Autoplay, Swipe, Breakpoints });
  }
};

export function initFrontpageMediaSlider() {
  initMediaSlider();
}
